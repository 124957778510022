import { Link } from '@remix-run/react'
import clsx from 'clsx'
import { Collection } from '~common/generated/storefront-graphql'
import { formatPrice } from '~common/utils/format/priceFormat'

interface CollectionCardProps {
  compact?: boolean
  collection: Collection
  detailUrl: string
  className?: string
}

export function CollectionCard({
  compact,
  collection,
  detailUrl,
  className,
}: CollectionCardProps) {
  const isLowestPriceAvailable =
    collection.customFields && collection.customFields.pricingLowest

  return (
    <Link
      to={detailUrl}
      prefetch="intent"
      key={collection.id}
      className={clsx(
        'relative rounded-4xl overflow-hidden shrink-0',
        {
          'min-w-[300px] xl:w-auto': !compact,
          'w-[175px]': compact,
        },
        className,
      )}
    >
      <div className="object-center object-cover overflow-hidden rounded-2xl bg-gray-50">
        {collection.featuredAsset?.preview ? (
          <img
            src={collection.featuredAsset?.preview + '?w=300&h=240'}
            className="rounded-2xl hover:scale-110 duration-300 transition ease-in-out"
          />
        ) : null}
      </div>
      <div className="w-full flex justify-between items-center mt-3">
        <p className="text-left text-xl font-bold text-black dark:text-slate-300">
          {collection.name}
        </p>
        {/* <p className="bg-dark-purple text-white py-1 px-2 rounded-md">
          10% OFF
        </p> */}
      </div>
      {isLowestPriceAvailable ? (
        <div
          className={clsx('w-full flex justify-between items-start mt-2', {
            'flex-col': compact,
          })}
        >
          <p
            className={clsx('text-gray-500', {
              'text-lg': !compact,
              'text-xs': compact,
            })}
          >
            Starts from
          </p>
          <div className="flex w-auto flex-col justify-start items-end">
            {/* <p className="text-md font-bold line-through">
              <span className="text-gray-400 dark:text-slate-400">$ 150.00</span>
              <span className="text-green-400"> / monthly</span>
            </p> */}
            <p
              className={clsx({
                'text-lg font-bold': !compact,
                'text-xs font-medium': compact,
              })}
            >
              <span>
                {formatPrice(Number(collection.customFields?.pricingLowest))}
              </span>
              {collection.customFields?.pricingInterval ? (
                <span className="text-green-600 ml-1">
                  / {collection.customFields?.pricingInterval}
                </span>
              ) : null}
            </p>
          </div>
        </div>
      ) : null}
    </Link>
  )
}
